import { Link as ScrollLink } from 'react-scroll';
import { useLocation, Outlet } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Icon } from '@iconify/react';
import facebook from '@iconify/icons-eva/facebook-outline';
import linkedin from '@iconify/icons-eva/linkedin-outline';
import github from '@iconify/icons-eva/github-outline';
import twitter from '@iconify/icons-eva/twitter-outline';

// material
import { Box, Container, Link, Stack, Typography } from '@material-ui/core';
import { varBounceInDown } from '../../components/animate';
// components
//
import MainNavbar from './MainNavbar';
import MainFooter from './MainFooter';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();
  const isHome = pathname === '/';

  return (
    <>
      <MainNavbar />

      <div>
        <Outlet />
      </div>

      {!isHome ? (
        <MainFooter />
      ) : (
        <Box
          sx={{
            py: 5,
            textAlign: 'center',
            position: 'relative',
            bgcolor: 'background.default'
          }}
        >
          <Container maxWidth="lg">
            <Box sx={{ width: '50px', height: '50px', mx: 'auto', mb: 3 }}>
              <motion.img variants={varBounceInDown} src="/static/logo.png" />
            </Box>
            <Stack direction="row" sx={{ justifyContent: 'center', mb: 5 }}>
              <Link
                rel="noreferrer"
                target="_blank"
                href="https://github.com/MiguelQXXV"
                sx={{
                  color: 'common.grey',
                  mx: 2,
                  transition: 'all 0.4s ease',
                  '&:hover': {
                    color: 'common.blue',
                    transform: 'scale(1.1)',
                    transition: 'all 0.4s ease'
                  }
                }}
              >
                <Icon icon={github} width={24} height={24} />
              </Link>
              <Link
                rel="noreferrer"
                target="_blank"
                href="https://www.linkedin.com/in/miguel-quintos/"
                sx={{
                  color: 'common.grey',
                  mx: 2,
                  transition: 'all 0.4s ease',
                  '&:hover': {
                    color: 'common.blue',
                    transform: 'scale(1.1)',
                    transition: 'all 0.4s ease'
                  }
                }}
              >
                <Icon icon={linkedin} width={24} height={24} />
              </Link>
              <Link
                rel="noreferrer"
                target="_blank"
                href="https://www.facebook.com/emqquintos/"
                sx={{
                  color: 'common.grey',
                  mx: 2,
                  transition: 'all 0.4s ease',
                  '&:hover': {
                    color: 'common.blue',
                    transform: 'scale(1.1)',
                    transition: 'all 0.4s ease'
                  }
                }}
              >
                <Icon icon={facebook} width={24} height={24} />
              </Link>
              <Link
                rel="noreferrer"
                target="_blank"
                href="https://www.twitter.com/@emqquintos"
                sx={{
                  color: 'common.grey',
                  mx: 2,
                  transition: 'all 0.4s ease',
                  '&:hover': {
                    color: 'common.blue',
                    transform: 'scale(1.1)',
                    transition: 'all 0.4s ease'
                  }
                }}
              >
                <Icon icon={twitter} width={24} height={24} />
              </Link>
            </Stack>

            <Typography variant="overline" component="p" sx={{ color: 'common.grey', fontWeight: 400 }}>
              © All rights reserved. Miguel Quintos
            </Typography>
          </Container>
        </Box>
      )}
    </>
  );
}

import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';
import facebook from '@iconify/icons-eva/facebook-outline';
import linkedin from '@iconify/icons-eva/linkedin-outline';
import github from '@iconify/icons-eva/github-outline';
import twitter from '@iconify/icons-eva/twitter-outline';

// material
import { styled } from '@material-ui/core/styles';
import { Box, Link, AppBar, Toolbar, Container, Stack, Typography } from '@material-ui/core';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
import { MHidden } from '../../components/@material-extend';
//
import MenuDesktop from './MenuDesktop';
// import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';
import { varBounceInDown, varFadeInRight } from '../../components/animate';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

// ----------------------------------------------------------------------

export default function MainNavbar() {
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const isHome = pathname === '/';

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            height: { md: APP_BAR_DESKTOP - 12 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Link component={RouterLink} to="/" underline="none">
            <Box sx={{ width: '250px', height: '50px', mx: 'auto', my: 1, display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '50px' }}>
                <motion.img variants={varBounceInDown} src="/static/logo.png" />
              </Box>
              <motion.div variants={varFadeInRight}>
                <Typography
                  variant="h4"
                  color="common.white"
                  sx={{
                    ml: 2,
                    fontWeight: '500',
                    ...(isOffset && {
                      opacity: 0,
                      transition: 'all 0.4s ease'
                    })
                  }}
                >
                  Miguel Quintos
                </Typography>
              </motion.div>
            </Box>
          </Link>

          <Box sx={{ flexGrow: 1 }} />

          <MHidden width="mdDown">
            <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />

            <Stack direction="row" sx={{ justifyContent: 'center' }}>
              <Link
                rel="noreferrer"
                target="_blank"
                href="https://github.com/MiguelQXXV"
                sx={{
                  color: 'common.grey',
                  mx: 2,
                  transition: 'all 0.4s ease',
                  '&:hover': {
                    color: 'common.blue',
                    transform: 'scale(1.1)',
                    transition: 'all 0.4s ease'
                  }
                }}
              >
                <Icon icon={github} width={24} height={24} />
              </Link>
              <Link
                rel="noreferrer"
                target="_blank"
                href="https://www.linkedin.com/in/miguel-quintos/"
                sx={{
                  color: 'common.grey',
                  mx: 2,
                  transition: 'all 0.4s ease',
                  '&:hover': {
                    color: 'common.blue',
                    transform: 'scale(1.1)',
                    transition: 'all 0.4s ease'
                  }
                }}
              >
                <Icon icon={linkedin} width={24} height={24} />
              </Link>
              <Link
                rel="noreferrer"
                target="_blank"
                href="https://www.facebook.com/emqquintos/"
                sx={{
                  color: 'common.grey',
                  mx: 2,
                  transition: 'all 0.4s ease',
                  '&:hover': {
                    color: 'common.blue',
                    transform: 'scale(1.1)',
                    transition: 'all 0.4s ease'
                  }
                }}
              >
                <Icon icon={facebook} width={24} height={24} />
              </Link>
              <Link
                rel="noreferrer"
                target="_blank"
                href="https://www.twitter.com/@emqquintos"
                sx={{
                  color: 'common.grey',
                  mx: 2,
                  transition: 'all 0.4s ease',
                  '&:hover': {
                    color: 'common.blue',
                    transform: 'scale(1.1)',
                    transition: 'all 0.4s ease'
                  }
                }}
              >
                <Icon icon={twitter} width={24} height={24} />
              </Link>
            </Stack>
          </MHidden>
          {/* 
          <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden> */}
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
